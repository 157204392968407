<template>
  <div class="homes">
    <div>
      <h1>welcome to ivsp</h1>
    </div>
  </div>
</template>

<script>

export default {
  name: '',
  components: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
  methods: {}
};
</script>
<style lang="less" scoped>
</style>
